<template>
  <vs-card class="p-5">
    <a @click="$router.go(-1)" class="block mb-10"> <feather-icon icon="ArrowLeftIcon" class="cursor-pointer mr-3" style="vertical-align: bottom"/>Back to product</a>
    <label>Article name</label>
    <vs-input v-model="articleName" class="mb-10 w-4/12" style="width: 100%" id="articleName" name="articleName" data-vv-validate-on="blur" data-vv-as="article name" v-validate="'required'"/>
    <span class="text-danger text-sm mt-3 mb-6 block" v-show="errors.has('articleName')">{{ errors.first("articleName") }}</span>
    <h3 class="text-base mb-10">Body</h3>
    <editor
      v-model="articleBody"
      id="articleBody"
      name="articleBody"
      data-vv-validate-on="blur"
      data-vv-as="article body"
      v-validate="'required'"
      ref="articleBodyEditor"
    ></editor>
    <span class="text-danger text-sm mt-3 mb-6 block" v-show="errors.has('articleBody')" >{{ errors.first("articleBody") }}</span>
    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
    <pop-up :errorsList="errors" form-name="ProductLegalEditorForm"></pop-up>
    <div class="flex justify-end items-center">
      <vs-button @click="add()" style="margin-top: 2em" class="w-full sm:w-auto" :disabled="!validateForm" v-round>
        {{ this.$route.query.action ? "Save" : "Create" }}
      </vs-button>
      <a @click="cancel()" style="margin-top: 2em" class="w-full sm:w-auto ml-5 underline"> Cancel</a>
    </div>
  </vs-card>
</template>

<script>
import _ from "lodash";
import Editor from "../../QuillEditor.vue";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { mapMutations, mapGetters ,mapState} from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    Editor,
    LeavePopup,
    PopUp,
    ValidationProvider,
  },
  data() {
    return {
      isButtonHidden: false,
      id: "",
      popupActive: false,
      isSaved: false,
      nextObj: "",
      articleBody: "",
      articleName: "",
    };
  },
  methods: {
    ...mapMutations("paymentPlans", ["ADD_INTEGRATION_GUIDE", "DELETE_INTEGRATION_GUIDE"]),

    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          this.add();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },

    goToPayLater() {
      let query = {};
      query.productType = this.$route.query.productType;
      query.integrationGuide = true;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      return this.$router.push({ name: "pay-later-product", query: query });
    },

    cancel() {
      if (this.$route.query.action !== "edit") {
        this.DELETE_INTEGRATION_GUIDE();
      }

      this.goToPayLater();
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    add() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const integrationGuide = {
            articleName: this.articleName,
            articleBody: this.articleBody,
            createdAt: new Date().toISOString(),
          };

          this.ADD_INTEGRATION_GUIDE(integrationGuide);
          this.isSaved = true;
          this.goToPayLater();
        }
      }).catch((ex) => {
        if (ex.name === "QuotaExceededError") {
          this.DELETE_INTEGRATION_GUIDE();
          this.showMessage("Error","Either the characters of the content or the size of the image file is too big", "danger", "icon-times");
        }
      });
    },

    handleContentValidation() {
      if (this.articleBody.length && this.errors.has("articleBody")) {
        this.errors.remove("articleBody");
      } else if (!this.articleBody.length && !this.errors.has("articleBody")) {
        this.errors.add({
          "field": "articleBody",
          "msg": "The article body field is required"
        });
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  beforeMount() {
    if (this.getCurrentPaymentPlan && this.getCurrentPaymentPlan.integrationGuide) {
      this.articleName = this.getCurrentPaymentPlan.integrationGuide.articleName;
      this.articleBody = this.getCurrentPaymentPlan.integrationGuide.articleBody;
    }
  },
  computed: {
    ...mapGetters("paymentPlans", ["getCurrentPaymentPlan"]),
    ...mapState("paymentPlans", ["currentPlan"]),
  },
  watch: {
    articleBody() {
      this.handleContentValidation();
    }
  }
};
</script>
